import React, { Component } from "react";
import { Grid, Row, Col } from "react-flexbox-grid";
import "react-step-progress-bar/styles.css";
import { ProgressBar } from "react-step-progress-bar";
import { AwesomeButtonProgress } from "react-awesome-button";
import { Parallax } from "react-parallax";
import "react-awesome-button/src/styles/themes/theme-c137/styles.scss";
import "./index.scss";
import { api } from "../../services/api";
import { translate } from "../../services/i18n";

export default class PlayerComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        playerData: {
          totalXP: 0,
          userNP: 0,
          isPlayerVIP: false,
          claimmed: [],
        },
        storeData: {
          packs: [],
          rewards: [],
        },
      },
    };
    this.currentItemSKU = "";
  }

  componentDidMount() {
    this.loadData();
  }

  loadData = async () => {
    let user = this.props.user;
    let token = this.props.token;
    let response = await api.get(
      `royal-rank-default.php?function=playerInfo&AccountName=${user}&token=${token}`
    );
    console.log(response.data);
    this.setState({ data: response.data });
  };

  buyItem = async () => {
    let user = this.props.user;
    let token = this.props.token;
    let response = await api.get(
      `royal-rank-default.php?function=buyItem&AccountName=${user}&item=${this.currentItemSKU}&token=${token}`
    );
    this.setState({ data: response.data });
  };

  claimItem = async () => {
    let user = this.props.user;
    let token = this.props.token;
    let response = await api.get(
      `royal-rank-default.php?function=claimItem&AccountName=${user}&item=${this.itemXP}&season=${this.season}&token=${token}`
    );
    this.setState({ data: response.data });
  };

  render() {
    if (
      this.state.loading ||
      !this.state.data.storeData ||
      !this.state.data.playerData
    )
      return <div></div>;

    const { packs, rewards } = this.state.data.storeData;
    const { isPlayerVIP, userNP, totalXP, claimmed } =
      this.state.data.playerData;

    let xp = { level: 1, current: 0, last: 0, total: 0 };
    let level = 1;
    let index = 0;

    packs.forEach((pack) => {
      pack.requiredLevel = 0;
    });

    rewards.forEach((element) => {
      level++;
      packs.forEach((pack) => {
        if (pack.requiredLevel === 0 && pack.minxp <= element.xp)
          pack.requiredLevel = level;
      });
      element.requiredLevel = level;
      element.claimmed = claimmed[index];
      element.index = index;
      index++;
      if (totalXP < element.xp) {
        if (xp.total === 0) {
          xp.total = element.xp - xp.last;
          xp.current = totalXP - xp.last;
        }
      } else {
        xp.last = element.xp;
        xp.level++;
      }
    });

    let parentNode =
      this.props.root === "site"
        ? window.parent.document.getElementsByClassName("scroll-manager")[0]
        : document;

    let availablePacks = 0;
    let storeComponent = (
      <Parallax
        blur={2}
        bgImage={"./img/bg-store.jpg"}
        bgAlt="WYD"
        strength={100}
        parent={parentNode}
      >
        <br />
        <Row center="xs">
          <Col className="player-np" xs={6} sm={5} md={4} lg={3}>
            <div className="card border-secondary border">
              <span>
                <span>
                  <div className="np-icon"></div>
                </span>
                {userNP}
              </span>
            </div>
          </Col>
        </Row>
        <br />
        <Row
          className="player-store"
          center="xs"
          xs={12}
          sm={12}
          md={12}
          lg={12}
        >
          {packs.map((element) => {
            const { price, VIP, XP, sku, minxp, maxxp, requiredLevel } =
              element;
            if ((VIP && isPlayerVIP) || (!VIP && totalXP >= maxxp)) return null;
            availablePacks++;
            return (
              <Col key={sku} xs={12} sm={12} md={6} lg={4}>
                <Col
                  className={VIP ? "store-item vip" : "store-item xp"}
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                >
                  <div>
                    <b>{translate("player.pack." + sku + ".name")}</b>
                  </div>
                  <div>{translate("player.pack." + sku + ".description")}</div>
                  <br />
                  <div>{translate("player.pack.content.title")}</div>
                  {VIP ? (
                    <div>
                      {translate("player.pack.content.vip")}{" "}
                      <img
                        className="pb-gif"
                        src={"./img/" + translate("tag") + "/pb.gif"}
                        alt="Passe de batalha"
                      />
                    </div>
                  ) : null}
                  {XP > 0 ? (
                    <div>
                      {XP} {translate("player.pack.content.xp")}
                    </div>
                  ) : null}
                  {VIP ^ (XP > 0) ? <br /> : null}
                  <br />
                  <Row center="xs">
                    <Col className="np-price" xs={5}>
                      <div className="np-icon"></div>
                      {price}
                    </Col>
                  </Row>
                  <br />
                  <Row center="xs">
                    <Col xs={5}>
                      <AwesomeButtonProgress
                        disabled={price > userNP || (!VIP && totalXP < minxp)}
                        type="primary"
                        action={async (event, endLoading) => {
                          this.currentItemSKU = sku;
                          await this.buyItem();
                          endLoading();
                        }}
                      >
                        {price > userNP
                          ? translate("player.pack.button.np")
                          : !VIP && totalXP < minxp
                          ? translate("player.pack.button.level") +
                            " " +
                            requiredLevel
                          : translate("player.pack.button.buy")}
                      </AwesomeButtonProgress>
                    </Col>
                  </Row>
                </Col>
                <br />
              </Col>
            );
          })}
        </Row>
      </Parallax>
    );

    storeComponent = availablePacks === 0 ? null : storeComponent;

    return (
      <div className="player-component">
        <Grid fluid>
          <Row className="banner-container">
            <Parallax
              className="banner-img"
              bgImage={"./img/t1.png"}
              bgAlt="WYD"
              strength={5}
              parent={parentNode}
            >
              <img
                className="banner-img-over"
                src={
                  "https://wydmisc.raidhut.com.br/royal-rank/" +
                  translate("tag") +
                  "/t2.png"
                }
                alt="season"
              ></img>
            </Parallax>
          </Row>
          <Row className="player-status" hidden={availablePacks === 0}>
            <Col xs={4} sm={4} md={3} lg={2}>
              <h2>
                {translate("player.level")} {xp.level}
              </h2>
            </Col>
            <Col xs={8} sm={8} md={9} lg={10}>
              <ProgressBar
                percent={(xp.current / xp.total) * 100}
                filledBackground="linear-gradient(to right, #7fffd4, #00ced1)"
              />
              <div>
                {xp.current}/{xp.total}
              </div>
            </Col>
          </Row>
          <hr />
          {storeComponent}
          <hr />
          <Row className="player-rewards">
            {rewards.map((element) => {
              const {
                name,
                itemId,
                isVIP,
                claimmed,
                requiredLevel,
                xp,
                season,
              } = element;
              let iconURL =
                "https://wydmisc.raidhut.com/icons/global/" + itemId + ".gif";
              let iconURL404 =
                "https://wydmisc.raidhut.com.br/icons/global-hd/0.png";
              let vip = isVIP ? (
                <img
                  className="pb-gif"
                  src={"./img/" + translate("tag") + "/pb.gif"}
                  alt="Passe de batalha"
                />
              ) : null;
              return (
                <Col key={xp} xs={12} sm={12} md={12} lg={12}>
                  <Col
                    className={
                      claimmed
                        ? "store-item claimmed"
                        : isVIP
                        ? "store-item vip"
                        : "store-item normal"
                    }
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                  >
                    <Row>
                      <Col xs={2} sm={2} md={1} lg={1}>
                        <div>
                          <b>
                            {translate("player.reward.level")}
                            <br />
                            {requiredLevel}
                          </b>
                        </div>
                      </Col>
                      <Col xs={1} sm={1} md={1} lg={1}>
                        <img
                          className={
                            claimmed ? "item-icon claimmed" : "item-icon"
                          }
                          style={{
                            backgroundImage: "url(" + iconURL + ")",
                            backgroundPosition: "center",
                            backgroundSize: "80%",
                            borderRadius: "12px",
                            margin: "0px",
                          }}
                          src={
                            "https://wydmisc.raidhut.com/icons/global/moldura.png"
                          }
                          alt={name}
                          onError={(e) => {
                            e.target.onError = null;
                            e.target.src = iconURL404;
                          }}
                        ></img>
                      </Col>
                      <Col xs={5} sm={5} md={6} lg={6}>
                        <h5>
                          {name
                            .replace("_", " ")
                            .replace("_", " ")
                            .replace("_", " ")}
                          {vip}
                        </h5>
                      </Col>
                      <Col xs={4} sm={4} md={4} lg={4}>
                        <AwesomeButtonProgress
                          size="mediun"
                          type="primary"
                          disabled={
                            claimmed !== 0 ||
                            (isVIP && !isPlayerVIP) ||
                            totalXP < xp
                          }
                          onPress={async (event, endLoading) => {
                            this.itemXP = xp;
                            this.season = season;
                            await this.claimItem();
                            endLoading();
                          }}
                        >
                          {claimmed
                            ? translate("player.reward.button.claimmed")
                            : totalXP < xp
                            ? translate("player.reward.button.level") +
                              " " +
                              requiredLevel
                            : isVIP && !isPlayerVIP
                            ? translate("player.reward.button.vip")
                            : translate("player.reward.button.claim")}
                        </AwesomeButtonProgress>
                      </Col>
                    </Row>
                  </Col>
                </Col>
              );
            })}
          </Row>
        </Grid>
      </div>
    );
  }
}
