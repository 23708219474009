import React, { useState, useEffect } from "react";
import { Grid, Row, Col } from "react-flexbox-grid";
import "./App.scss";
import RankComponent from "./components/rank";
import PlayerComponent from "./components/player";
import { validateToken } from "./services/session";
import { setLanguageToI18n } from "./services/i18n";
import SendLog from "./services/SendLog";

function App() {
  const [authenticated, setAuthenticated] = useState(false);
  const [isUnderMaintenance, setIsUnderMaintenance] = useState(false);

  useEffect(() => {
    const params = new URL(window.location.href).searchParams;
    SendLog();
    if (params.has("accountName") && params.has("token")) {
      const accountName = params.get("accountName");
      const token = params.get("token");
      (async () => {
        setAuthenticated(await validateToken(accountName, token));
      })();
    }
    checkServiceStatus();
  }, []);

  const checkServiceStatus = async () => {
    try {
      const response = await fetch(
        "https://qhvn7xul7up4cldommobkkop3a0hygca.lambda-url.us-east-1.on.aws/service?service=royal_rank"
      );
      if (response.ok) {
        const data = await response.json();
        if (data.status === 0) {
          setIsUnderMaintenance(true);
        }
      }
    } catch (error) {
      console.error("Error checking service status:", error);
    }
  };

  if (!authenticated) return null;

  if (isUnderMaintenance) {
    return (
      <div className="maintenance">
        <div className="maintenance-content">
          <h1>Under Maintenance</h1>
          <p>
            The service is currently under maintenance. Please come back later.
          </p>
        </div>
      </div>
    );
  }

  const params = new URL(window.location.href).searchParams;
  const accountName = params.get("accountName");
  const token = params.get("token");
  const root = params.get("root");
  setLanguageToI18n(params.has("lang") ? params.get("lang") : "en_US");

  return (
    <Grid fluid className="main-content">
      <br />
      <div className="overlay overflow-hidden pe-n">
        <img src="img/bg_shape.png" alt="background shape" />
      </div>
      <Row>
        <Col xs={12} sm={12} md={12} lg={8}>
          <PlayerComponent user={accountName} token={token} root={root} />
        </Col>
        <Col xs={12} sm={12} md={12} lg={4}>
          <RankComponent user={accountName} token={token} root={root} />
        </Col>
      </Row>
    </Grid>
  );
}

export default App;
