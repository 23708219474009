export const en = {
    tag: 'en-US',
    player: {
        level: 'Level',
        pack: {
            com: {
                raidhut: {
                    season: {
                        6: {
                            pass: {
                                name: 'Common Battle Pass',
                                description: 'Purchase the common battle pass to unlock the redemption of all rewards of your level or lower.'
                            },
                            pass_plus: {
                                name: 'VIP Battle Pass',
                                description: 'Purchase the VIP battle pass to unlock the redemption of all rewards of your level or lower and start with 800 EXP on the pass.'
                            },
                            boost: {
                                name: 'Boost',
                                description: 'Get the boost to accelerate your progress on the battle pass with +500 EXP.'
                            }
                        }
                    }
                }
            },
            content: {
                title: 'Contains:',
                vip: 'Battle Pass',
                xp: 'experience'
            },
            button: {
                buy: 'Buy',
                level: 'Level required',
                np: 'Needs more NP'
            }
        },
        reward: {
            level: 'LEVEL',
            button: {
                claimmed: 'Claimmed',
                level: 'Level required',
                vip: 'Battle pass required',
                claim: 'Claim'
            }
        }
    },
    rank: {
        tables: {
            Win: 'Wins',
            Kill: 'Kills',
            XP: 'Experience'
        },
        columns: {
            position: 'Position',
            nickname: 'Character',
            score: 'Score (Rate)'
        }
    }
}