import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Grid, Row, Col } from "react-flexbox-grid";
import "./index.scss";
import { api } from "../../services/api";
import { translate } from "../../services/i18n";

export default class RankComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ranks: {
        Win: { title: translate("rank.tables.Win"), content: [] },
        Kill: { title: translate("rank.tables.Kill"), content: [] }, //,
        //"XP":{"title":translate('rank.tables.XP'),"content":[]}
      },
    };
  }

  componentDidMount() {
    this.loadData();
  }

  loadData = async () => {
    let user = this.props.user;
    let token = this.props.token;
    let response = await api.get(
      `royal-rank-default.php?function=rank&AccountName=${user}&token=${token}`
    );
    this.setState({ ranks: response.data, loading: false });
  };

  render() {
    const tabs = [];
    const tabPanels = [];

    Object.keys(this.state.ranks).forEach((name) => {
      const { content } = this.state.ranks[name];
      tabs.push(
        <Tab key={name} className="rank-tab">
          {translate("rank.tables." + name)}
        </Tab>
      );
      let odd = true;
      tabPanels.push(
        <TabPanel key={name} className="rank-tab-panel">
          <Grid fluid className="rank-list">
            <Row className="row--title">
              <Col xs={3} sm={3} md={3} lg={3}>
                {translate("rank.columns.position")}
              </Col>
              <Col xs={5} sm={5} md={5} lg={5}>
                {translate("rank.columns.nickname")}
              </Col>
              <Col xs={4} sm={4} md={4} lg={4}>
                {translate("rank.columns.score")}
              </Col>
            </Row>
            <hr className="line-hr" />
            {content.map((element) => {
              odd = !odd;
              const { type, position, nick, points, isVIP } = element;
              let vip = isVIP ? (
                <img
                  className="pb-gif"
                  src={"./img/" + translate("tag") + "/pb.gif"}
                  alt="Passe de batalha"
                />
              ) : null;
              return (
                <div key={position}>
                  <Row
                    className={
                      type === "self"
                        ? "row--self"
                        : odd
                        ? "row--marked"
                        : "row"
                    }
                  >
                    <Col xs={3} sm={3} md={3} lg={3}>
                      {position}
                    </Col>
                    <Col xs={5} sm={5} md={5} lg={5}>
                      {nick}
                      {vip}
                    </Col>
                    <Col xs={4} sm={4} md={4} lg={4}>
                      {points}
                    </Col>
                  </Row>
                </div>
              );
            })}
          </Grid>
        </TabPanel>
      );
    });
    return (
      <div className="rank-component bg-dark_A-20">
        <Tabs
          selectedTabClassName="rank-tab--selected"
          selectedTabPanelClassName="rank-tab-panel--selected"
        >
          <TabList className="rank-tab-list">{tabs}</TabList>
          {tabPanels}
        </Tabs>
      </div>
    );
  }
}
