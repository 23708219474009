import axios from "axios";

export default async function SendLog() {
  const resIp = await axios.get("http://icanhazip.com");
  axios({
    method: "post",

    url: "https://goiabada.tapiocahut.com/api/support/logs/launcherapp",

    headers: {},

    data: {
      log: {
        ip: resIp.data.replace("\n", ""),

        platform: "undefined",

        resource: "royal-rank",
      },
    },
  });
}
