export const pt = {
    tag: 'pt-BR',
    player: {
        level: 'Level',
        pack: {
            com: {
                raidhut: {
                    season: {
                        6: {
                            pass: {
                                name: 'Passe de Batalha Comum',
                                description: 'Adquira o passe de batalha comum para desbloquear o resgate de todas as recompensas do seu nível ou inferior.'
                            },
                            pass_plus: {
                                name: 'Passe de Batalha VIP',
                                description: 'Adquira o passe de batalha VIP para desbloquear o resgate de todas as recompensas do seu nível ou inferior e já começar com 800 de EXP no passe.'
                            },
                            boost: {
                                name: 'Boost',
                                description: 'Adquira o boost para acelerar o seu progresso no passe de batalha com +500 de EXP.'
                            }
                        }
                    }
                }
            },
            content: {
                title: 'Contém:',
                vip: 'Passe de batalha',
                xp: 'experiência'
            },
            button: {
                buy: 'Comprar',
                level: 'Requer level',
                np: 'Falta NP'
            }
        },
        reward: {
            level: 'LEVEL',
            button: {
                claimmed: 'Já resgatado',
                level: 'Requer level',
                vip: 'Requer passe de batalha',
                claim: 'Resgatar'
            }
        }
    },
    rank: {
        tables: {
            Win: 'Vitórias',
            Kill: 'Mortes',
            XP: 'Experiência'
        },
        columns: {
            position: 'Posição',
            nickname: 'Personagem',
            score: 'Pontuação (Taxa)'
        }
    }
}